<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="名称/编号" label-width="80px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item label="年级" label-width="50px" prop="ninji">
				  <el-select v-model="queryForm.nianji" placeholder="请选择年级" style="width: 100%;">
				    <el-option :value="item.id" :label="item.name" v-for="(item,index) in nianji" :key="item.id"/>
				  </el-select>
				</el-form-item>
				<el-form-item label="学科" label-width="50px" prop="xueke">
				  <el-select v-model="queryForm.xueke" placeholder="请选择学科" style="width: 100%;">
				    <el-option :value="item.id" :label="item.name" v-for="(item,index) in xueke" :key="item.id"/>
				  </el-select>
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<!-- <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">申请</el-button> -->
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ scope.row.id }}
				  </template>
				</el-table-column>
				<el-table-column label="申请编号" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.code || ''}}</span>
				  </template>
				</el-table-column>
				<el-table-column label="学期" align="center" min-width="100">
				  <template slot-scope="scope">
					<span>{{ scope.row.year +' '+ scope.row.tag}}</span>
				  </template>
				</el-table-column>
				<el-table-column label="姓名" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.t_name || ''}}</span>
				  </template>
				</el-table-column>
				<el-table-column label="年级" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.nianji || ''}}</span>
				  </template>
				</el-table-column>
				<el-table-column label="学科" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.xueke || ''}}</span>
				  </template>
				</el-table-column>
				<el-table-column label="状态" min-width="60" align="center">
				  <template slot-scope="scope">
						<el-tag v-if="scope.row.status === 1" size="mini" type="success">已审核</el-tag>
						<el-tag v-if="scope.row.status === 2" size="mini" type="warning">待审核</el-tag>
						<el-tag v-if="scope.row.status === 3" size="mini" type="danger">驳回</el-tag>
				  </template>
				</el-table-column>
				<el-table-column label="分数" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.score || ''}}</span>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="160">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.utime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="审核" placement="top" v-if="scope.row.status == 2">
					  <el-button type="warning" icon="el-icon-setting" size="mini" circle @click="handleOperate(scope.row)"></el-button>		
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="撤回" placement="top" v-if="scope.row.status == 1">
					  <el-button type="danger" icon="el-icon-refresh-left" size="mini" circle @click="handleRecall('aszd',scope.row)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="600px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
			<el-form-item  label="审核状态" prop="status">
				<el-radio-group v-model="form.status">
				   <el-radio :label="1">通过</el-radio>
				   <el-radio :label="3">驳回</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="分数" prop="score">
				<el-input type="number" v-model="form.score" placeholder="请输入得分"></el-input>
				<small class="text-danger font-s">最大分数1分</small>
			</el-form-item>
			<el-form-item label="反馈意见" v-if="form.status == 3">
				<el-input type="textarea" v-model="form.remarks" placeholder="请输入反馈意见" :rows="3" maxlength="500" show-word-limit></el-input>
			</el-form-item>
		    <el-form-item label="分数指标">
		      <div class="w-100 flex flex-column">
		      	<div class="w-100 mb-2">
		      		按照日常考勤进行评价，给出评价分0-1分，每缺勤一次扣0.2分。
		      	</div>
		      </div>
		    </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			const checkMax = (rule, value, callback) => {
			  if (value > 1) {
				callback(new Error('最大值是1'))
			  } else {
				callback()
			  }
			}
			return {
				preUrl: 'user_aszd',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
					nianji:'',
					xueke:'',
					role_id:this.VueCookies.get('role_id'),
				},
				nianji:[],
				xueke:[],
				form:{
					id:'',
					status:'',
					score:'',
					remarks:'',
				},
				rules:{
					status: [
					  { required: true, message: '请选择审核状态', trigger: 'blur' }
					],
					score: [
					  // { required: true, message: '请填写审核分数', trigger: ['blur', 'change'] },
					  { validator: checkMax, trigger: ['blur', 'change'] },
					],
				},
			}
		},
		mounted() {
			this.getList()
			this.getNianji()
			this.getXueke()
		},
		methods:{
			getList(){
				this.loading = true
				this.axios.get(`/manage/${this.preUrl}/operate_list`, {
				  params: this.queryForm
				}).then(res => {
				  this.total = parseInt(res.total)
				  this.list = res.data
				  this.loading = false
				}).catch(err => {
				  this.loading = false
				})
			},
			handleOperate(row){
				this.reset()
				this.open = true
				this.title = '审核'
				this.form.id = row.id
			},
			reset() {
			  this.form = {
			    id:'',
			    status:'',
			    score:'',
			    remarks:'',
			  }
			  this.resetForm('form')
			},
			handleSubmit(){
				this.$refs.form.validate(valid => {
				  if (valid) {
					  this.axios.post('/manage/'+this.preUrl + '/operate', this.form).then(res => {
					    if (res.status) {
					      this.$message.success('审核成功')
					      this.open = false
					      this.getList()
					    } else {
					      this.$message.error(res.msg)
					    }
					  })
				  }
				})
			},
		},
	}
</script>

<style>
</style>